
import { computed, ComputedRef, defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessageBox, ElMessage } from 'element-plus';
import { useTopics, useDeleteTopic } from '@/composables/api';
import {
  toggleTopic,
  TopicIdOption,
  DEFAULT_PAGE_SIZE,
  DELETED_SUCCESSFULLY_TEXT,
  MAX_PER_PAGE,
  ResponseError,
  getClassifications
} from '@/services/api';
import permissionUnits from '@/components/permission-units/index.vue';
import { formatLocalTime } from '@/utils/format-time';
import { Classification, TopicType } from '@/interfaces';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import { typeTranslate } from './components/TopicDetail.vue';
import { useKeyword, usePage } from '@/composables';

export default defineComponent({
  components: {
    permissionUnits,
    SearchBox,
    Filter
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);

    const classifications = ref<Classification[]>([]);
    const classificationIds = ref();
    const classificationOptions = computed(() => classifications.value.map(({ name, id }) => ({ label: `${name}`, value: `${id}` })));
    const type = ref();
    const lang = ref('all');

    const { data, isLoading, isFetching, refetch } = useTopics({ page, keyword, pageSize, type, classificationIds, lang });
    const { isLoading: isDeleting, mutate } = useDeleteTopic();

    const fetchClassifications = async() => {
      classifications.value = (await getClassifications({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      classificationIds.value = event[0];
      type.value = event[1];
    };

    const filterOptions: ComputedRef<FilterOption[]> = computed(() => {
      return [
        {
          type: FilterType.MULTI_SELECTOR,
          label: '主類型',
          placeholder: '請選擇',
          options: classificationOptions.value
        },
        {
          type: FilterType.SELECTOR,
          label: '主題內容',
          placeholder: '請選擇',
          options: Object
            .entries(TopicType)
            .map(([label, value]) => ({ label: typeTranslate[label], value }))
        }
      ];
    });

    const handleToggleTopic = async({ topicId }: TopicIdOption) => {
      try {
        await toggleTopic({ topicId });
        refetch.value();
      } catch (error) {
        ElMessage.error((error as ResponseError).response?.data.message);
      }
    };

    const currentDeletedIndex = ref<number>();
    const deleteTopic = ({ topicId }: TopicIdOption, index: number) => {
      currentDeletedIndex.value = index;

      ElMessageBox.confirm('是否確認要刪除？', '警告',
        {
          confirmButtonText: '刪除',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          mutate({ topicId }, {
            onSuccess() {
              refetch.value();

              ElMessage.success(DELETED_SUCCESSFULLY_TEXT);
            },
            onError(error: ResponseError) {
              ElMessage.error(error.response?.data.message);
            }
          });
        })
        .catch();
    };

    onMounted(() => {
      fetchClassifications();
    });

    return {
      page,
      data,
      isLoading,
      isFetching,
      isDeleting,
      currentDeletedIndex,
      TopicType,
      filterOptions,
      keyword,
      handleToggleTopic,
      deleteTopic,
      formatLocalTime,
      handleFilterChange,
      setPageQuery
    };
  }
});
